import SparkleIcon from '../icons/sparkles.svg'
import NoiseIcon from '../icons/noise.svg'
import ShareIcon from '../icons/share.svg'
import SmileIcon from '../icons/smile.svg'
import TranslateLRIcon from '../icons/translateLeftRight.svg'
import TranslateLIcon from '../icons/translateLeft.svg'
import TranslateRIcon from '../icons/translateRight.svg'
import TranslateUDIcon from '../icons/translateUpDown.svg'
import TranslateUIcon from '../icons/translateUp.svg'
import TranslateDIcon from '../icons/translateDown.svg'
import ScaleLRIcon from '../icons/scaleLeftRight.svg'
import ScaleLIcon from '../icons/scaleLeft.svg'
import ScaleRIcon from '../icons/scaleRight.svg'
import ScaleUDIcon from '../icons/scaleUpDown.svg'
import ScaleUIcon from '../icons/scaleUp.svg'
import ScaleDIcon from '../icons/scaleDown.svg'
import ScaleBothUIcon from '../icons/scaleBothUp.svg'
import ScaleBothDIcon from '../icons/scaleBothDown.svg'
import MirrorLRIcon from '../icons/mirrorLeftRight.svg'
import MirrorUDIcon from '../icons/mirrorUpDown.svg'
import RotateLIcon from '../icons/rotateLeft.svg'
import RotateRIcon from '../icons/rotateRight.svg'
import CrossEyedIcon from '../icons/crossEyed.svg'
import ColorLIcon from '../icons/colorLeft.svg'
import ColorRIcon from '../icons/colorRight.svg'


import BeardIcon from '../icons/beard.svg'
import BrowIcon from '../icons/brow.svg'
import CheeksIcon from '../icons/cheeks.svg'
import EarRingR1Icon from '../icons/earRingR1.svg'
import EarRingR2Icon from '../icons/earRingR2.svg'
import EarRingR3Icon from '../icons/earRingR3.svg'
import EarRingR4Icon from '../icons/earRingR4.svg'
import EarRingL1Icon from '../icons/earRingL1.svg'
import EarRingL2Icon from '../icons/earRingL2.svg'
import EarRingL3Icon from '../icons/earRingL3.svg'
import EarRingL4Icon from '../icons/earRingL4.svg'
import EyeballIcon from '../icons/eyeball.svg'
import EyeGlossIcon from '../icons/eyeGloss.svg'
import EyelinerIcon from '../icons/eyeliner.svg'
import EyeShadowIcon from '../icons/eyeShadow.svg'
import EyeTatUpperIcon from '../icons/eyeTatUpper.svg'
import EyeTatLowerIcon from '../icons/eyeTatLower.svg'
import EyeRingLIcon from '../icons/eyeRingL.svg'
import EyeRingRIcon from '../icons/eyeRingR.svg'
import GagIcon from '../icons/gag.svg'
import GlassesIcon from '../icons/glasses.svg'
import HairIcon from '../icons/hair.svg'
import HairBackIcon from '../icons/hairBack.svg'
import HatIcon from '../icons/hat.svg'
import HeadIcon from '../icons/head.svg'
import HornLIcon from '../icons/hornL.svg'
import HornRIcon from '../icons/hornR.svg'
import LashUnderIcon from '../icons/lashUnder.svg'
import LashUpperIcon from '../icons/lashUpper.svg'
import LipsIcon from '../icons/lips.svg'
import LipstickIcon from '../icons/lipstick.svg'
import MoustacheIcon from '../icons/moustache.svg'
import NecklaceOuterIcon from '../icons/necklaceOuter.svg'
import NecklaceUnderIcon from '../icons/necklaceUnder.svg'
import NoseIcon from '../icons/nose.svg'
import NoseRingLIcon from '../icons/noseRingL.svg'
import NoseRingRIcon from '../icons/noseRingR.svg'
import NoseTipIcon from '../icons/noseTip.svg'
import PaintCheekLIcon from '../icons/paintCheekL.svg'
import PaintCheekRIcon from '../icons/paintCheekR.svg'
import PaintEyeIcon from '../icons/paintEye.svg'
import PaintForeheadIcon from '../icons/paintForehead.svg'
import PaintMouthIcon from '../icons/paintMouth.svg'
import PupilBothIcon from '../icons/pupilBoth.svg'
import PupilLeftIcon from '../icons/pupilLeft.svg'
import PupilRightIcon from '../icons/pupilRight.svg'
import SeptumIcon from '../icons/septum.svg'
import ShirtIcon from '../icons/shirt.svg'
import TearInnerIcon from '../icons/tearInner.svg'
import TearOuterIcon from '../icons/tearOuter.svg'

import MessagePosIcon from '../icons/messagePos.svg'
import HandLeftIcon from '../icons/handLeft.svg'
import HandRightIcon from '../icons/handRight.svg'
import SmokeIcon from '../icons/smoke.svg'
import FrameIcon from '../icons/frame.svg'
import MessageIcon from '../icons/message.svg'
import CigIcon from '../icons/cigarette.svg'
import CheekTatRightIcon from '../icons/cheekTattooRight.svg'
import CheekTatLeftIcon from '../icons/cheekTattooLeft.svg'
import ForeheadTattooIcon from '../icons/foreheadTattoo.svg'
import EyeClosedIcon from '../icons/eyeClosed.svg'
import NeckTattooIcon from '../icons/neckTattoo.svg'
import { ToggleInteger, TogglePercent, MLPChoices, ToggleKey,  } from '../../shared/PutinChoices'

const translateUpDown =  {up: TranslateDIcon, down: TranslateUIcon}
const translateLeftRight =  {up: TranslateRIcon, down: TranslateLIcon}

const changeAsset =  {up: TranslateRIcon, down: TranslateLIcon}

const scaleUpDown =  {up: ScaleUIcon, down: ScaleDIcon}
const scaleLeftRight =  {up: ScaleRIcon, down: ScaleLIcon}

const scaleBoth =  {up: ScaleBothUIcon, down: ScaleBothDIcon}

const rotateLeftRight =  {up: RotateRIcon, down: RotateLIcon}
const colorLeftRight =  {up: ColorRIcon, down: ColorLIcon}


export const WrinklesToggles = {
    name: "Skin",
    options: [
        {
            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.skin.color},
                },
            ]
        },
        {
            name: "Wrinkles",
            group: [
                {
                    icon: ForeheadTattooIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.skin.wrinkles.forehead,
                },
                {
                    icon: EyeGlossIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.skin.wrinkles.eyes},
                },
                {
                    icon: LipsIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.skin.wrinkles.mouth},
                },
            ]
        },
        {
            name: "Freckles",
            group: [
                {
                    upDownIcon: HeadIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.freckles.src,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.freckles.scalePercent,
                },
            ]
        },
    ]
}

export const HeadToggles = {
    name: "Head",
    options: [
        {
            name: "Head",
            group: [
                {
                    upDownIcon: HeadIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.head.src,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.head.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.head.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.head.scalePercent.y,
                },
            ]
        },
    ]
}

export const NoseToggles = {
    name: "Nose",
    options: [
        {

            name: "Position",
            group: [
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.scalePercent.y,
                },
            ]
        }
    ]
}

export const EarToggles = {
    name: "Ears",
    options: [
        {

            name: "Position",
            group: [
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.scalePercent.y,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.rotationPercent,
                },
            ]
        }
    ]
}

export const MouthToggles = {
    name: "Mouth",
    options: [
        {

            name: "Mouth",
            group: [
                {
                    upDownIcon: LipsIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.src,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.scalePercent.y,
                },
            ]
        }
    ]
}

const BlushToggles = {
    name: "Blush",
    options: [
        {

            name: "Blush",
            group: [
                {
                    icons: translateLeftRight,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.src,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.offsetPercent.y,
                },
            ]
        },
    ]
}

export const EmotionToggles = {
    name: "Emotion",
    options: [
        {
            name: "Crying",
            group: [
                {
                    icon: TearInnerIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.crying.inner,
                },
                {
                    icon: TearOuterIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.crying.outer,
                },
            ]
        },
        {
            name: "Blush",
            group: [
                {
                    icon: CheeksIcon,
                    separator: true,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.src,
                },
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.outer.offsetPercent.y,
                },
            ]
        },
    ]
}

export const EyeballToggles = {
    name: "Eyeball",
    options: [
        {
            name: "Gloss",
            group: [
                {
                    icon: EyeGlossIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.light.src ,
                },
            ]
        },
        {
            name: "Open",
            group: [
                {
                    icon: EyeClosedIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.closed,
                },
                {
                    icon: EyeClosedIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.closed,
                },
            ]
        },
        {
            name: "Eyeball",
            group: [
                {
                    icon: EyeballIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.white.src ,
                },
                {
                    icon: EyeballIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.white.src ,
                },
            ]
        },
        // {
        //     name: "Squint",
        //     group: [
        //         {
        //             icon: EyeballIcon,
        //             choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.squint ,
        //         },
        //         {
        //             icon: EyeballIcon,
        //             choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.squint ,
        //         },
        //     ]
        // },
    ]
}

export const TearsToggles = {
    name: "Cry",
    options: [
        {
            name: "Crying",
            group: [
                {
                    upDownIcon: TearInnerIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.crying.inner,
                },
                {
                    upDownIcon: TearOuterIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.crying.outer,
                },
            ]
        },
    ]
}

export const PupilToggles = {
    name: "Pupils",
    tabs: [
        {
            name: "Both",
            options: [
                {
                    name: "Pupil",
                    group: [
                        {
                            upDownIcon: PupilBothIcon,
                            separator: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.src, choices.putin.eyes.right.pupil.src]} },
                        },
                        {
                            icons: colorLeftRight,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.tint, choices.putin.eyes.right.pupil.tint]} },
                        },
                    ]
                },
                {
                    name: "Position",
                    group: [
                        {
                            slider: "horizontal",
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.offsetPercent.x, choices.putin.eyes.right.pupil.offsetPercent.x]} },
                        },
                        {
                            slider: "vertical-flip",
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.offsetPercent.y, choices.putin.eyes.right.pupil.offsetPercent.y]} },
                        },
                    ]
                },
                {
                    name: "Size",
                    group: [
                        {
                            slider: "vertical", // scaleBoth
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.scalePercent, choices.putin.eyes.right.pupil.scalePercent]} },
                        },
                    ]
                },
            ]
        },
        {
            name: "Left",
            options: [
                {
        
                    name: "Pupil",
                    group: [
                        {
                            upDownIcon: PupilLeftIcon,
                            separator: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.left.pupil.src },
                        },
                        {
                            icons: colorLeftRight,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.left.pupil.tint },
                        },
                    ]
                },
                {
                    name: "Position",
                    group: [
                        {
                            slider: "horizontal", // translateLeftRight
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.offsetPercent.x,
                        },
                        {
                            slider: "vertical-flip", // translateUpDown
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.offsetPercent.y,
                        },
                    ]
                },
                {
                    name: "Size",
                    group: [
                        {
                            slider: "vertical", // scaleBoth
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.scalePercent,
                        },
                    ]
                },
            ]
        },
        {
            name: "Right",
            options: [
                {
        
                    name: "Pupil",
                    group: [
                        {
                            upDownIcon: PupilRightIcon,
                            separator: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.right.pupil.src },
                        },
                        {
                            icons: colorLeftRight,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.right.pupil.tint },
                        },
                    ]
                },
                {
                    name: "Position",
                    group: [
                        {
                            slider: "horizontal", // translateLeftRight
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.offsetPercent.x,
                        },
                        {
                            slider: "vertical-flip", // translateUpDown
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.offsetPercent.y,
                        },
                    ]
                },
                {
                    name: "Size",
                    group: [
                        {
                            slider: "vertical", // scaleBoth
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.scalePercent,
                        },
                    ]
                },
            ]
        }
    ]
}


export const PupilBothToggles = {
    fullname: "Both Pupils",
    name: "Pupils",
    options: [
        {
            name: "Pupil",
            group: [
                {
                    upDownIcon: PupilBothIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.src, choices.putin.eyes.right.pupil.src]} },
                },
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.tint, choices.putin.eyes.right.pupil.tint]} },
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal",
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.offsetPercent.x, choices.putin.eyes.right.pupil.offsetPercent.x]} },
                },
                {
                    slider: "vertical-flip",
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.offsetPercent.y, choices.putin.eyes.right.pupil.offsetPercent.y]} },
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.pupil.scalePercent, choices.putin.eyes.right.pupil.scalePercent]} },
                },
            ]
        },
    ]
}

export const PupilLToggles = {
    fullname: "Left Pupil",
    name: "L",
    options: [
        {

            name: "Pupil",
            group: [
                {
                    upDownIcon: PupilLeftIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.left.pupil.src },
                },
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.left.pupil.tint },
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.offsetPercent.y,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.pupil.scalePercent,
                },
            ]
        },
    ]
}

export const PupilRToggles = {
    fullname: "Right Pupil",
    name: "R",
    options: [
        {

            name: "Pupil",
            group: [
                {
                    upDownIcon: PupilLeftIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.right.pupil.src },
                },
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return choices.putin.eyes.right.pupil.tint },
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.offsetPercent.y,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.pupil.scalePercent,
                },
            ]
        },
    ]
}

export const EyeToggles = {
    name: "Eyes",
    options: [
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.scalePercent.y,
                },
            ]
        },
        {
            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.rotationPercent,
                },
            ]
        },
    ]
}

export const HairColorToggles = {
    name: "Hair Color",
    options: [
        {

            name: "All Hair",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.hair.color, choices.putin.eyes.brow.color, choices.putin.moustache.color,] } }
                },
            ]
        },
        {

            name: "Head",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hair.color,
                },
            ]
        },
        {

            name: "Brows",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.color,
                },
            ]
        },
        {

            name: "Facial",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.moustache.color,
                },
            ]
        },
    ]
}

export const HairToggles = {
    name: "Hair",
    options: [
        {
            name: "Top",
            group: [
                {
                    upDownIcon: HairIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hair.fgSrc,
                },
                {
                    icon: MirrorLRIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hair.fgFlip,
                },
            ]
        },
        {
            name: "Back",
            group: [
                {
                    upDownIcon: HairBackIcon,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hair.bgSrc,
                },
                {
                    icon: MirrorLRIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hair.bgFlip,
                },
            ]
        },
    ]
}

export const BrowToggles = {
    name: "Brows",
    options: [
        {
            name: "Brow",
            group: [
                {
                    upDownIcon: BrowIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.pool,
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.scalePercent,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.brow.rotationPercent,
                },
            ]
        },
    ]
}

export const LashToggles = {
    name: "Lashes",
    options: [
        {

            name: "Top",
            group: [
                {
                    upDownIcon: LashUpperIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.lashTop.src,
                },
            ]
        },
        {

            name: "Bottom",
            group: [
                {
                    upDownIcon: LashUnderIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.lashBottom.src,
                },
            ]
        },
    ]
}

export const FacialHairToggles = {
    name: "Facial Hair",
    options: [
        {

            name: "Beard",
            group: [
                {
                    upDownIcon: BeardIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.beard.src,
                },
            ]
        },
        {

            name: "Moustache",
            group: [
                {
                    upDownIcon: MoustacheIcon,
                    separator: true,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.moustache.src,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.moustache.offsetPercent.y,
                },
                {
                    slider: "horizontal", // scaleLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.moustache.scalePercent.x,
                },
            ]
        },
    ]
}

const _Liner = (num: "1" | "2" | "3" | "4") => { return {
    name: "" + num,
    options: [
        {

            name: "Eyeliner",
            group: [
                {
                    upDownIcon: EyelinerIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeliner"+num].src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeliner"+num].color,
                },
            ]
        },
        {

            name: "Side",
            group: [
                {
                    icon: PupilLeftIcon,
                    canDisable: true,
                    ignoreRandomize: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return { base: choices.putin.eyes["eyeliner"+num].hide, value: {i: 1}  } },
                },
                {
                    icon: PupilBothIcon,
                    canDisable: true,
                    ignoreRandomize: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) =>  { return { base: choices.putin.eyes["eyeliner"+num].hide, value: {i: 0} } },
                },
                {
                    icon: PupilRightIcon,
                    canDisable: true,
                    ignoreRandomize: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) =>  { return { base: choices.putin.eyes["eyeliner"+num].hide, value: {i: 2} } },
                },
            ]
        },
    ]
}}

export const EyeLinerToggles = {
    name: "Eyeliner",
    tabs: [
        _Liner("1"),
        _Liner("2"),
        _Liner("3"),
        _Liner("4"),
    ]
}


export const EyeLiner1Toggles = {
    ..._Liner("1"),
    name: "Eyeliner 1",
}

export const EyeLiner2Toggles = {
    ..._Liner("2"),
    name: "2",
    fullname: "Eyeliner 2",
}

export const EyeLiner3Toggles = {
    ..._Liner("3"),
    name: "3",
    fullname: "Eyeliner 3",
}

export const EyeLiner4Toggles = {
    ..._Liner("4"),
    name: "4",
    fullname: "Eyeliner 4",
}

const _EyeShadow = (num: "1" | "2") => {return{
    name: "Shadow " + num,
    options: [
        {
            name: "Shadow",
            group: [
                {
                    upDownIcon: EyeShadowIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeshadow" + num].src,
                },
            ]
        },
        {
            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeshadow" + num].color,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeshadow" + num].scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes["eyeshadow" + num].scalePercent.y,
                },
            ]
        },
    ]
}}

export const EyeShadowToggles = {
    name: "Eyeshadow",
    tabs: [
        _EyeShadow("1"),
        _EyeShadow("2"),
    ],
}

export const EyeShadow1Toggles = {
    ..._EyeShadow("1"),
    name: "Eyeshadow 1",
}

export const EyeShadow2Toggles = {
    ..._EyeShadow("2"),
    name: "2",
    fullname: "Eyeshadow 2",
}



export const CheekToggles = {
    name: "Cheeks",
    options: [
        {

            name: "Cheeks",
            group: [
                {
                    upDownIcon: CheeksIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.color,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "horizontal", // scaleLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.scalePercent.x,
                },
                {
                    slider: "vertical", // scaleUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks.scalePercent.y,
                },
            ]
        },
    ]
}


const _CheekPaintToggles = (k: "left" | "right")  => { return {
    options: [
        {

            name: "Paint",
            group: [
                {
                    upDownIcon: PaintCheekLIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.color,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.scalePercent,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal",
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cheeks[k].paint.rotationPercent,
                },
            ]
        },
    ]
}}


export const CheekPaintLToggles = {
    ..._CheekPaintToggles("left"),
    name: "L",
    fullname: "Cheek Paint Left",
}

export const CheekPaintRToggles = {
    ..._CheekPaintToggles("right"),
    name: "R",
    fullname: "Cheek Paint Right",
}

export const CheekBothPaintToggles = {
    fullname: "Both Cheeks Paint",
    name: "Cheeks",
    options: [
        {
            name: "Paint",
            group: [
                    {
                        upDownIcon: PaintCheekLIcon,
                        disableIfDefault: true,
                        choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.cheeks.left.paint.src, choices.putin.cheeks.right.paint.src,]} }
                    },
                ]
            ,
        },
        {
            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.cheeks.left.paint.color, choices.putin.cheeks.right.paint.color]}}
                },
            ]
            ,
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {base: choices.putin.cheeks.left.paint.offsetPercent.x, mirror: choices.putin.cheeks.right.paint.offsetPercent.x}},
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.cheeks.left.paint.offsetPercent.y, choices.putin.cheeks.right.paint.offsetPercent.y]}},
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.cheeks.left.paint.scalePercent, choices.putin.cheeks.right.paint.scalePercent]}},
                },
            ]
        },
        {
            name: "Rotation",
            group: [
                    {
                        slider: "horizontal",
                        precision: 32,
                        canDisable: true,
                        choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {base: choices.putin.cheeks.left.paint.rotationPercent, mirror: choices.putin.cheeks.right.paint.rotationPercent}}
                    },
                ]
            ,
        },
    ]
}

export const LiptstickToggles = {
    name: "Lipstick 1",
    options: [
        {

            name: "Lipstick",
            group: [
                {
                    upDownIcon: LipstickIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.lipstick.src,
                },
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.lipstick.color,
                },
            ]
        },
    ]
}

export const Liptstick2Toggles = {
    name: "2",
    fullname: "Lipstick 2",
    options: [
        {

            name: "Lipstick",
            group: [
                {
                    upDownIcon: LipstickIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.lipstick2.src,
                },
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.lipstick2.color,
                },
            ]
        },
    ]
}

export const MouthPaintToggles = {
    name: "Mouth",
    options: [
        {
            name: "Mouth",
            group: [
                {
                    upDownIcon: PaintMouthIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.paint.src,
                },
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.paint.color,
                },
            ]
        },
    ]
}


export const HandToggles = {
    name: "Hands",
    options: [
        {
            name: "Left Hand",
            group: [
                {
                    upDownIcon: HandLeftIcon,
                    disableIfDefault: true,
                    separator: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hands.left.src,
                },
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hands.left.offsetPercent.x,
                },
            ]
        },
        {
            name: "Right Hand",
            group: [
                {
                    upDownIcon: HandRightIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hands.right.src,
                    separator: true
                },
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hands.right.offsetPercent.x,
                },
            ]
        },
        {
            name: "Smoke",
            group: [
                {
                    icon: SmokeIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.smoke,
                },
            ]
        },
    ]
}

export const ShirtToggles = {
    name: "Shirt",
    options: [
        {

            name: "Shirt",
            group: [
                {
                    upDownIcon: ShirtIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.shoulders.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.shoulders.colorPercent,
                },
            ]
        },
    ]
}

const CigaretteToggles = {
    name: "Mouth",
    options: [
        {

            name: "Accessory",
            group: [
                {
                    upDownIcon: CigIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.src,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.offsetPercent.x,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.rotationPercent,
                },
            ]
        },
        {
            name: "Smoke",
            group: [
                {
                    icon: SmokeIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.smoke,
                },
            ]
        },
        // {

        //     name: "Flip",
        //     group: [
        //         {
        //             upDownIcon: MirrorLRIcon,
        //             choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.cigarette.scale.x,
        //         },
        //     ]
        // },
    ]
}

const GagToggles = {
    name: "Gag",
    options: [
        {
            name: "Gag",
            group: [
                {
                    upDownIcon: GagIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.gag.src,
                },
            ]
        },
        // {
        //     name: "Position",
        //     group: [
        //         {
        //             slider: "vertical-flip", // translateUpDown
        //             choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.mouth.offsetPercent.y,
        //         },
        //     ]
        // },
    ]
}

export const MouthAccessoryToggles = {
    name: "Mouth",
    tabs: [CigaretteToggles]
}

export const HatToggles = {
    name: "Hat",
    options: [
        {
            name: "Hat",
            group: [
                {
                    upDownIcon: HatIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hat.src,
                },
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.hat.colorPercent,
                },
            ]
        },
    ]
}


export const HornLToggles = {
    name: "L",
    fullname: "Left Horns",
    options: [
        {

            name: "Horn",
            group: [
                {
                    upDownIcon: HornLIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.color,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.scalePercent,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontLeft.rotationPercent,
                },
            ]
        },
    ]
}

export const HornRToggles = {
    name: "R",
    fullname: "Right Horns",
    options: [
        {

            name: "Horn",
            group: [
                {
                    upDownIcon: HornRIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.color,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal-flip", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.scalePercent,
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.horns.frontRight.rotationPercent,
                },
            ]
        },
    ]
}

export const HornBothToggles = {
    name: "Horns",
    fullname: "Both Horns",
    options: [
        {

            name: "Horn",
            group: [
                {
                    upDownIcon: HornLIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.src, choices.putin.horns.frontRight.src]} }
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.color, choices.putin.horns.frontRight.color]} }
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.offsetPercent.x, choices.putin.horns.frontRight.offsetPercent.x]} }
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.offsetPercent.y, choices.putin.horns.frontRight.offsetPercent.y]} }
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.scalePercent, choices.putin.horns.frontRight.scalePercent]} }
                },
            ]
        },
        {

            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // rotateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.horns.frontLeft.rotationPercent, choices.putin.horns.frontRight.rotationPercent]} }
                },
            ]
        },
    ]
}

export const ForeheadToggles = {
    
    name: "Forehead",
    options: [
        {

            name: "Forehead",
            group: [
                {
                    upDownIcon: PaintForeheadIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.src,
                },
            ]
        },
        {

            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.color,
                },
            ]
        },
        {

            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.offsetPercent.y,
                },
            ]
        },
        {

            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.scalePercent,
                },
            ]
        },
        {

            name: "Rotation",
            precision: 32,
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.forehead.paint.rotationPercent,
                },
            ]
        },
    ]
}


export const MessageToggles = {
    name: "Message",
    tabs: [
        {
            name: "Message",
            options: [
                {
                    name: "Message",
                    group: [
                        {
                            upDownIcon: MessageIcon,
                            disableIfDefault: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.src,
                        },
                    ]
                },
                {

                    name: "Color",
                    group: [
                        {
                            icons: colorLeftRight,
                            canDisable: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.color,
                        },
                    ]
                },
                {

                    name: "Position",
                    group: [
                        {
                            upDownIcon: MessagePosIcon,
                            canDisable: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.position,
                        },
                    ]
                },
                {

                    name: "Size",
                    group: [
                        {
                            slider: "vertical", // scaleBoth
                            precision: 5,
                            canDisable: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.scalePercent,
                        },
                    ]
                },
                {

                    name: "Filter",
                    group: [
                        {
                            upDownIcon: SparkleIcon,
                            canDisable: true,
                            choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.filter,
                        },
                    ]
                },
            ]
        },
    ]
}

export const FrameToggles = {
    name: "Frame",
    options: [
        {
            name: "Frame",
            group: [
                {
                    icons: translateLeftRight,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.frame.src,
                },
            ]
        },
        {
            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.frame.color,
                },
            ]
        },
        {
            name: "Filter",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.frame.filter,
                },
            ]
        },
    ]
}

export const OutlineToggles = {
    name: "Outline",
    options: [
        {

            name: "Outline",
            group: [
                {
                    icons: translateLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.putin.outline.size,
                },
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.putin.outline.color,
                },
            ]
        },
    ]
}

export const BackgroundToggles = {
    name: "Background",
    options: [
        {

            name: "Background",
            group: [
                {
                    icons: translateLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.background.choice,
                },
            ]
        },
        {

            name: "Extra",
            group: [
                {
                    icons: translateLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.background.extra,
                },
            ]
        },
        {
            name: "Filter",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.background.filter,
                },
            ]
        },
        {

            name: "Vignette",
            group: [
                {
                    icon: NoiseIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.vignette,
                },
            ]
        },
    ]
}

export const EffectsToggles = {
    name: "Putin",
    options: [
        {
            name: "Filter",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.putin.filter,
                },
            ]
        },
        {

            name: "Outline",
            group: [
                // {
                //     icons: translateLeftRight,
                //     choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.putin.outline.size,
                // },
                {
                    icons: colorLeftRight,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.putin.outline.color,
                },
            ]
        },
        {
            name: "Film Grain",
            group: [
                {
                    icon: NoiseIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.grain,
                },
            ]
        },
    ]
}

export const FilterToggles = {
    name: "Filters",
    options: [
        {
            name: "Background",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.background.filter,
                },
            ]
        },
        {
            name: "Message",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.foreground.filter,
                },
            ]
        },
        {
            name: "Frame",
            group: [
                {
                    upDownIcon: SparkleIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.global.frame.filter,
                },
            ]
        },
    ]
}


const CollarToggles = {
    name: "Collar",
    options: [
        {

            name: "Collar",
            group: [
                {
                    upDownIcon: NecklaceOuterIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.neck.collar.src,
                },
            ]
        },
    ]
}

const _NecklaceToggles = {
    name: "Necklace",
    options: [
        {

            name: "Necklace",
            group: [
                {
                    upDownIcon: NecklaceUnderIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.neck.necklace.src,
                },
            ]
        },
    ]
}

export const NecklaceToggles = {
    name: "Neck",
    options: [
        {

            name: "Necklace",
            group: [
                {
                    upDownIcon: NecklaceUnderIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.neck.necklace.src,
                },
            ]
        },
        {

            name: "Collar",
            group: [
                {
                    upDownIcon: NecklaceOuterIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.neck.collar.src,
                },
            ]
        },
    ]
}

export const NoseMakeupToggles = {
    name: "Nose",
    options: [
        {
            name: "Makeup",
            group: [
                {
                    upDownIcon: NoseTipIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.makeup.tip.src,
                },
            ]
        },
        {
            name: "Color",
            group: [
                {
                    icons: colorLeftRight,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.makeup.tip.color,
                },
            ]
        },
    ]
}

export const GlassesToggles = {
    name: "Eyes",
    options: [
        {
            name: "Eyes",
            group: [
                {
                    upDownIcon: GlassesIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.glasses.src,
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.glasses.offsetPercent.y,
                },
            ]
        },
    ]
}



export const NoseAccessoryToggles = {
    name: "Nose",
    options: [
        {
            name: "Accessory",
            group: [
                {
                    upDownIcon: NoseIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.accessory.src,
                },
            ]
        },
        // {
        //     name: "Position",
        //     group: [
        //         {
        //             slider: "vertical-flip", // translateUpDown
        //             canDisable: true,
        //             choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.accessory.offsetPercent.y,
        //         },
        //     ]
        // },
    ]
}



const _ScarToggles = (name: string, k: "1" | "2" | "3" | "4") => { return {name, options:[
    {
        name: "Blemish",
        group: [
            {
                upDownIcon: ForeheadTattooIcon,
                disableIfDefault: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].src,
            },
        ]
    },
    {
        name: "Position",
        group: [
            {
                slider: "horizontal", // translateLeftRight
                precision: 32,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].offsetPercent.x,
            },
            {
                slider: "vertical-flip", // translateUpDown
                precision: 32,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].offsetPercent.y,
            },
        ]
    },
    {
        name: "Size",
        group: [
            {
                slider: "vertical", // scaleBoth
                precision: 32,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].scalePercent,
            },
        ]
    },
    {
        name: "Rotation",
        group: [
            {
                slider: "horizontal", // scaleBoth
                precision: 32,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].rotationPercent,
            },
        ]
    },
    {
        name: "Flip",
        group: [
            {
                icon: MirrorLRIcon,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.scars["scar" + k].flip,
            },
        ]
    }
]
}}

export const ScarToggles = {
    name: "Blemish",
    tabs: [
        _ScarToggles("1", "1"),
        _ScarToggles("2", "2"),
        _ScarToggles("3", "3"),
        _ScarToggles("4", "4"),
    ]
}

export const Scar1Toggles = {
    ... _ScarToggles("Blemish 1", "1"),
    fullname: "Blemish 1"
 }

export const Scar2Toggles = {
    ... _ScarToggles("2", "2"),
    fullname: "Blemish 2"
}

export const Scar3Toggles = {
   ... _ScarToggles("3", "3"),
   fullname: "Blemish 3"
}

export const Scar4Toggles = {
   ... _ScarToggles("4", "4"),
   fullname: "Blemish 4"
}


const _EyeTattooToggles = (name: string, tatk: "eyeLTop" | "eyeLBottom" | "eyeRTop" | "eyeRBottom") => { return {name, options:[
    {
        name: "Tattoo",
        group: [
            {
                disableIfDefault: true,
                upDownIcon: tatk == "eyeLBottom" || tatk == "eyeRBottom" ? EyeTatLowerIcon : EyeTatUpperIcon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].src,
            },
        ]
    },
    {
        name: "Size",
        group: [
            {
                canDisable: true,
                slider: "vertical", // scaleBoth
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].scalePercent,
            },
        ]
    },
]}}

export const EyeUnderTattooToggles = {
    name: "Eyes (Under)",
    tabs: [
        _EyeTattooToggles("Bottom L", "eyeLBottom"),
        _EyeTattooToggles("Bottom R", "eyeRBottom"),
    ]
}


export const EyeUnderLTattooToggles = {
    ..._EyeTattooToggles("", "eyeLBottom"),
    name: "Under Eye L",
    fullname: "Under Eye Tattoo Left",
}

export const EyeUnderRTattooToggles = {
    ..._EyeTattooToggles("", "eyeRBottom"),
    name: "R",
    fullname: "Under Eye Tattoo Right",
}

export const EyeAboveTattooToggles = {
    name: "Eyes (Above)",
    tabs: [
        _EyeTattooToggles("Top L", "eyeLTop"),
        _EyeTattooToggles("Top R", "eyeRTop"),
    ]
}


export const EyeAboveLTattooToggles = {
    ..._EyeTattooToggles("", "eyeLTop"),
    name: "Above Eye L",
    fullname: "Above Eye Tattoo Left",
}

export const EyeAboveRTattooToggles = {
    ..._EyeTattooToggles("", "eyeRTop"),
    name: "R",
    fullname: "Above Eye Tattoo Right",
}

const _ForeheadTattooToggles = (name: string, tatk: "forehead1" | "forehead2" | "forehead3") => { return {name, options:[
        {
            name: "Tattoo",
            group: [
                {
                    disableIfDefault: true,
                    upDownIcon: ForeheadTattooIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].src,
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    canDisable: true,
                    slider: "horizontal", // translateLeftRight
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].offsetPercent.x,
                },
                {
                    canDisable: true,
                    slider: "vertical-flip", // translateUpDown
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].offsetPercent.y,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].scalePercent,
                },
            ]
        },
        {
            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // scaleBoth
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].rotationPercent,
                },
            ]
        },
        {
            name: "Flip",
            group: [
                {
                    icon: MirrorLRIcon,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[tatk].flip,
                },
            ]
        }
    ]
}}

export const ForeheadTattooToggles = {
    name: "Forehead",
    tabs: [
        _ForeheadTattooToggles("Tat 1", "forehead1"),
        _ForeheadTattooToggles("Tat 2", "forehead2"),
        _ForeheadTattooToggles("Tat 3", "forehead3"),
    ]
}

export const Forehead1TattooToggles = {
    ..._ForeheadTattooToggles("", "forehead1"),
    name: "Forehead 1",
    fullname: "Forehead Tattoo 1",
}

export const Forehead2TattooToggles = {
    ..._ForeheadTattooToggles("", "forehead2"),
    name: "2",
    fullname: "Forehead Tattoo 2",
}

export const Forehead3TattooToggles = {
    ..._ForeheadTattooToggles("", "forehead3"),
    name: "3",
    fullname: "Forehead Tattoo 3",
}

const _NeckTat = (name: string, tatk: "neck1" | "neck2" | "neck3") => { 
    return {name, options:[
        {
            name: "Tattoo",
            group: [
                {
                    upDownIcon: NeckTattooIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].src,
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].offsetPercent.y,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].scalePercent,
                },
            ]
        },
        {
            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // scaleBoth
                    canDisable: true,
                    precision: 32,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].rotationPercent,
                },
            ]
        },
        {
            name: "Flip",
            group: [
                {
                    icon: MirrorLRIcon,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos[tatk].flip,
                },
            ]
        }
    ]}
}

export const NeckTattooToggles = {
    name: "Neck",
    tabs: [
        _NeckTat("Tattoo 1", "neck1"),
        _NeckTat("Tattoo 2", "neck2"),
        _NeckTat("Tattoo 3", "neck3"),
    ]
}

export const Neck1TattooToggles = {
    ..._NeckTat("", "neck1"),
    name: "Neck 1",
    fullname: "Neck Tattoo 1",
}

export const Neck2TattooToggles = {
    ..._NeckTat("", "neck2"),
    name: "2",
    fullname: "Neck Tattoo 2",
}

export const Neck3TattooToggles = {
    ..._NeckTat("", "neck3"),
    name: "3",
    fullname: "Neck Tattoo 3",
}

const _CheekTattoo = (name: "cheekL" | "cheekR") => { return {
    name: "Left",
    options: [
        {
            name: "Tattoo",
            group: [
                {
                    upDownIcon: CheekTatLeftIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].src,
                },
            ]
        },
        {
            name: "Position",
            group: [
                {
                    slider: "horizontal", // translateLeftRight
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].offsetPercent.x,
                },
                {
                    slider: "vertical-flip", // translateUpDown
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].offsetPercent.y,
                },
            ]
        },
        {
            name: "Size",
            group: [
                {
                    slider: "vertical", // scaleBoth
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].scalePercent,
                },
            ]
        },
        {
            name: "Rotation",
            group: [
                {
                    slider: "horizontal", // scaleBoth
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].rotationPercent,
                },
            ]
        },
        {
            name: "Flip",
            group: [
                {
                    icon: MirrorLRIcon,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.tattoos.face[name].flip,
                },
            ]
        }
    ]

}}


export const CheekLTattooToggles = {
    ..._CheekTattoo("cheekL"),
    name: "Cheek L",
    fullname: "Cheek Tattoo Left",
}

export const CheekRTattooToggles = {
    ..._CheekTattoo("cheekR"),
    name: "R",
    fullname: "Cheek Tattoo Right",
}

export const EyePaintBothToggles = {
    fullname: "Both Eyes Paint",
    name: "Eyes",
    options: [{
        name: "Paint",
        group: [
                {
                    upDownIcon: PaintEyeIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.paint.src, choices.putin.eyes.right.paint.src,]} }
                },
            ]
        ,
    },
    {
        name: "Color",
        group: [
            {
                icons: colorLeftRight,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.paint.color, choices.putin.eyes.right.paint.color]}}
            },
        ]
        ,
    },
    {
        name: "Rotation",
        group: [
                {
                    slider: "horizontal",
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => { return {match: [choices.putin.eyes.left.paint.rotationPercent, choices.putin.eyes.right.paint.rotationPercent]}}
                },
            ]
        ,
    }],
}

export const EyePaintLToggles = {
    fullname: "Left Eye Paint",
    name: "L",
    options: [{
        name: "Paint",
        group: [
                {
                    upDownIcon: PaintEyeIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.paint.src,
                },
            ]
        ,
    },
    {
        name: "Color",
        group: [
            {
                icons: colorLeftRight,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.paint.color,
            },
        ]
        ,
    },
    {
        name: "Rotation",
        group: [
                {
                    slider: "horizontal",
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.paint.rotationPercent,
                },
            ]
        ,
    }],
}

export const EyePaintRToggles = {
    fullname: "Right Eye Paint",
    name: "R",
    options: [{
        name: "Paint",
        group: [
                {
                    upDownIcon: PaintEyeIcon,
                    disableIfDefault: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.paint.src,
                },
            ]
        ,
    },
    {
        name: "Color",
        group: [
            {
                icons: colorLeftRight,
                canDisable: true,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.paint.color,
            },
        ]
        ,
    },
    {
        name: "Rotation",
        group: [
                {
                    slider: "horizontal",
                    precision: 32,
                    canDisable: true,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.paint.rotationPercent,
                },
            ]
        ,
    }],
}


export const NosePiercingToggles = {
    name: "Nose",
    options: [
        {
            name: "Nostrils",
            group: [
                {
                    icon: NoseRingLIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.piercings.ringL,
                },
                {
                    icon: NoseRingRIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.piercings.ringR,
                },
            ]
        },
        {

            name: "Septum",
            group: [
                {
                    icon: SeptumIcon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.nose.piercings.septum.src,
                },
            ]
        },
    ]
}

export const EarPiercingsToggles = {
    name: "Ears",
    fullname: "Both Ears",
    options: [
        {
            name: "Lower",
            group: [
                {
                    icon: EarRingL1Icon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => {return {match:[choices.putin.ear.left.earring.lower1,choices.putin.ear.right.earring.lower1,]}}
                },
                {
                    icon: EarRingL2Icon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => {return {match:[choices.putin.ear.left.earring.lower2,choices.putin.ear.right.earring.lower2,]}}
                },
            ]
        },

        {
            name: "Upper",
            group: [
                {
                    icon: EarRingL3Icon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => {return {match:[choices.putin.ear.left.earring.upper1,choices.putin.ear.right.earring.upper1,]}}
                },
                {
                    icon: EarRingL4Icon,
                    choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => {return {match:[choices.putin.ear.left.earring.upper2,choices.putin.ear.right.earring.upper2,]}}
                },
            ]
        }
    ],
}

export const EarPiercingLeftToggles = {
    name: "L",
    fullname: "Left Ear",
    options: [{
        name: "Lower",
        group: [
            {
                icon: EarRingL1Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.left.earring.lower1,
            },
            {
                icon: EarRingL2Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.left.earring.lower2,
            },
        ]
    },
    {
        name: "Upper",
        group: [
            {
                icon: EarRingL3Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.left.earring.upper1,
            },
            {
                icon: EarRingL4Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.left.earring.upper2,
            },
        ]
    }],
}

export const EarPiercingRightToggles = {
    name: "R",
    fullname: "Right Ear",
    options: [{
        name: "Lower",
        group: [
            {
                icon: EarRingR1Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.right.earring.lower1,
            },
            {
                icon: EarRingR2Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.right.earring.lower2,
            },
        ]
    },
    {
        name: "Upper",
        group: [
            {
                icon: EarRingR3Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.right.earring.upper1,
            },
            {
                icon: EarRingR4Icon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.ear.right.earring.upper2,
            },
        ]
    }],
}

export const BrowPiercingToggles = {
    name: "Brow",
    options: [
    {
        name: "Brow Piercings",
        group: [
            {
                icon: EyeRingLIcon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.left.brow.piercing.src,
            },
            {
                icon: EyeRingRIcon,
                choice: (choices: MLPChoices<ToggleInteger, TogglePercent, ToggleKey>) => choices.putin.eyes.right.brow.piercing.src,
            },
        ]
    },
       
    ]
}
