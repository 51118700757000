import React from 'react';
import { isSpecificValue, lookAtToggle } from '../../shared/PutinChoices';

import { BrowPiercingToggles, BrowToggles, EmotionToggles, CheekToggles, EarToggles, EyeShadowToggles, EyeAboveTattooToggles, EyeUnderTattooToggles, EyeToggles, MouthPaintToggles, FacialHairToggles, ForeheadToggles, NoseAccessoryToggles, GlassesToggles, HairToggles, HatToggles, HeadToggles, LashToggles, LiptstickToggles, MessageToggles, MouthToggles, NecklaceToggles, NosePiercingToggles, NoseToggles, OutlineToggles, PupilToggles, ShirtToggles, EyeballToggles, NoseMakeupToggles, HandToggles, TearsToggles, MouthAccessoryToggles, FilterToggles, ForeheadTattooToggles, NeckTattooToggles, BackgroundToggles, FrameToggles, EffectsToggles, ScarToggles, HairColorToggles, WrinklesToggles, EyeLiner1Toggles, EyeLiner2Toggles, EyeLiner3Toggles, EyeLiner4Toggles, EyeShadow1Toggles, EyeShadow2Toggles, Scar1Toggles, Scar2Toggles, Scar3Toggles, Scar4Toggles, EyeUnderLTattooToggles, EyeUnderRTattooToggles, EyeAboveRTattooToggles, EyeAboveLTattooToggles, CheekLTattooToggles, CheekRTattooToggles, Forehead1TattooToggles, Forehead2TattooToggles, Forehead3TattooToggles, Neck1TattooToggles, Neck2TattooToggles, Neck3TattooToggles, CheekPaintLToggles, CheekPaintRToggles, EyePaintBothToggles, EyePaintLToggles, EyePaintRToggles, PupilBothToggles, PupilLToggles, PupilRToggles, CheekBothPaintToggles, HornLToggles, HornBothToggles, HornRToggles, EarPiercingsToggles, EarPiercingLeftToggles, EarPiercingRightToggles, Liptstick2Toggles } from '../components/CustomizeControlGroups'
import { ContentCard } from './ContentCard';
import MyLittlePutin from './MyLittlePutin';


type CustomizePutinProps = { // props
    getMLP:()=>MyLittlePutin | null,
    modeColor: string
}

type CustomizePutinState = {
    currentCustomizeItem: {
        section: number, option: number, tab: number,
    },
    force: boolean
}

// tslint:disable-next-line:no-var-requires
require("../pages/index.less");

export default class CustomizePutin extends React.Component<CustomizePutinProps, CustomizePutinState> {

    constructor(props) {
        super(props);
        this.state = {
            currentCustomizeItem: { 
                section:0, option: 0, tab: 0,
            },
            force: false
        }
    }
    
    forceRender() {
        this.setState({force: !this.state.force})
    }

    sectioncontrolScrollerRef = React.createRef<HTMLDivElement>();
    currentSectionButtonRef: React.RefObject<HTMLButtonElement>;

    scrollingTableRef = React.createRef<HTMLDivElement>();

    controlScrollerRef = React.createRef<HTMLDivElement>();
    currentButtonRef: React.RefObject<HTMLButtonElement>;

    togglesTableScrollerRef = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: Readonly<CustomizePutinProps>, prevState: Readonly<CustomizePutinState>, snapshot?: any): void {
        const scrollToButton = (button: React.RefObject<HTMLButtonElement>, scroller: React.RefObject<HTMLDivElement>) => {
            scroller?.current?.scrollTo({
                top: 0,
                left: button?.current?.offsetLeft + button?.current?.clientWidth / 2 - scroller?.current?.offsetLeft - scroller?.current?.clientWidth / 2,
                behavior: 'smooth'
            });
        }
        scrollToButton(this.currentButtonRef, this.controlScrollerRef);
        scrollToButton(this.currentSectionButtonRef, this.sectioncontrolScrollerRef);
        this.togglesTableScrollerRef?.current?.scrollTo({left: 0, behavior: 'smooth'})
    }

    render(): React.ReactNode {
        const choices = this.props.getMLP()?.getChoices();

        const sectionPutin = {
            Base: {
                color: "skin",
                sectionItems: [
                    HeadToggles,
                    WrinklesToggles,
                    NoseToggles,
                    MouthToggles,
                    EarToggles,
                    Scar1Toggles,
                    Scar2Toggles,
                    Scar3Toggles,
                    Scar4Toggles,
                ]
            },
            "Eyes": {
                color: "eye",
                sectionItems: [
                    EyeToggles,
                    EyeballToggles,
                    PupilBothToggles,
                    PupilLToggles,
                    PupilRToggles,
                ]
            },
        }

        const sectionHair = {
            "Hair": {
                name: "Hair",
                color: "hair",
                sectionItems: [
                    HairToggles,
                    BrowToggles,
                    LashToggles,
                    FacialHairToggles,
                    HairColorToggles,
                ]
            },
        }

        const sectionMakeup = {
            "Makeup": {
                color: "purple",
                sectionItems: [
                    EyeLiner1Toggles,
                    EyeLiner2Toggles,
                    EyeLiner3Toggles,
                    EyeLiner4Toggles,
                    EyeShadow1Toggles,
                    EyeShadow2Toggles,
                    NoseMakeupToggles,
                    CheekToggles,
                    LiptstickToggles,
                    Liptstick2Toggles
                ]
            },
        }

        const sectionTattoo = {
            "Tattoos": {
                color: "darkGray",
                sectionItems: [
                    EyeUnderLTattooToggles,
                    EyeUnderRTattooToggles,
                    EyeAboveLTattooToggles,
                    EyeAboveRTattooToggles,
                    CheekLTattooToggles,
                    CheekRTattooToggles,
                    Forehead1TattooToggles,
                    Forehead2TattooToggles,
                    Forehead3TattooToggles,
                    Neck1TattooToggles,
                    Neck2TattooToggles,
                    Neck3TattooToggles
                ]
            },
        }

        const sectionPaint = {
            "Face Paint": {
                color: "pink",
                sectionItems: [
                    EyePaintBothToggles,
                    EyePaintLToggles,
                    EyePaintRToggles,
                    CheekBothPaintToggles,
                    CheekPaintLToggles,
                    CheekPaintRToggles,
                    MouthPaintToggles,
                    ForeheadToggles,
                ]
            },
        }

        const sectionAccessories = {
            Accessories: {
                color: "orange",
                sectionItems: [
                    ShirtToggles,
                    HatToggles,
                    NecklaceToggles,
                    GlassesToggles,
                    NoseAccessoryToggles,
                    MouthAccessoryToggles,
                    HandToggles,
                    HornBothToggles,
                    HornLToggles,
                    HornRToggles,
                    EmotionToggles,
                ]
            },
        }
        const sectionPiercings = {
            "Piercings": {
                color: "gray",
                sectionItems: [
                    NosePiercingToggles,
                    EarPiercingsToggles,
                    EarPiercingLeftToggles,
                    EarPiercingRightToggles,
                    BrowPiercingToggles,
                ]
            },
        }

        const sectionScene = {
            Scene: {
                color: "yellow",
                sectionItems: [
                    EffectsToggles,
                    BackgroundToggles,
                    FrameToggles,
                    MessageToggles,
                ]
            }
        }

        const sections = {
            sectionPutin,
            sectionHair,
            sectionScene,
            sectionMakeup,
            sectionPaint,
            sectionTattoo,
            sectionPiercings,
            sectionAccessories,
        }

        const optionSections = {
            ...sectionPutin,
            ...sectionHair,
            ...sectionMakeup,
            ...sectionPaint,
            ...sectionTattoo,
            ...sectionAccessories,
            ...sectionPiercings,
            ...sectionScene,
        }

        const currentSectionIndex = this.state.currentCustomizeItem.section
        const currentOptionIndex = this.state.currentCustomizeItem.option;
        const currentTabIndex = this.state.currentCustomizeItem.tab;

        const getTweakables = (sectionGroup, tab) => {
            if(sectionGroup.tabs) {
                return sectionGroup.tabs[tab]?.options;
            } else {
                return sectionGroup.options;
            }
        }

        const makeCustomizationItems = (optionSections) => {
            // section is like scene, or base
            const currentSection = optionSections[Object.keys(optionSections)[currentSectionIndex]]

            // group is like head, eyes, nose, etc
            const currentSectionGroup = currentSection?.sectionItems[currentOptionIndex]

            // should be section > group > tabs > option

            // sometimes an option group has a tab of groups like left eye, etc
            let currentSectionGroupTabs = currentSectionGroup.tabs;
            let currentSectionTweakables = getTweakables(currentSectionGroup,this.state.currentCustomizeItem.tab);

            const makeJumpToButton = (title, selected, callback) => {
                const colorClass = selected ? "white black-border" : "gray";
                if(selected) {
                    this.currentSectionButtonRef = React.createRef<HTMLButtonElement>(); 
                }
                return <button key={"JUMP "+title} onClick={callback} style={{ flex: "1", borderRadius: "5px", padding: "5px", fontSize: "14px", minHeight: "22px" }} ref={selected ? this.currentSectionButtonRef : null} className={"mlp-button-flex " + colorClass}>
                    {title}
                </button>
            }

            const makeTabButton = (title, tabIndex) => {
                const selected = this.state.currentCustomizeItem.tab == tabIndex;
                return <button key={"tabber" + tabIndex.toString()} style={{minHeight: "30px",borderRadius: "6px", flex: "1", color: selected ? "white" : "black", backgroundColor: selected ? "#00000044" : "#FFFFFFCC"}} onClick={selected ? null : () => {
                    this.setState({ currentCustomizeItem: {...this.state.currentCustomizeItem, tab: tabIndex}})
                }} className={"mlp-button-flex "}>
                    {title}
                </button>
            }

            const makeTabArrow = (direction: 1 | -1) => {
                const disabled = false;
                return <button key={"tabberArrow" + direction.toString()} style={{minHeight: "30px", fontWeight: "900", borderRadius: "6px", fontSize: "20px", maxWidth: "40px", flex: "1", color: "black", backgroundColor:"#FFFFFFFF"}} onClick={disabled ? null : () => {
                    const numberOfTabs = currentSectionGroupTabs?.length ?? 1
                    const numberOfSectionItems = currentSection?.sectionItems?.length ?? 1
                    const numberOfSections = Object.keys(optionSections)?.length ?? 1

                    const prevSection = optionSections[Object.keys(optionSections)[currentSectionIndex - 1]]
                    const prevSectionItem = prevSection?.sectionItems[prevSection.sectionItems.length - 1] 

                    const prevSectionTabs = prevSectionItem?.tabs;

                    // let tab = Math.max(Math.min(numberOfTabs - 1, this.state.currentCustomizeItem.tab + direction), 0)
                    let section = currentSectionIndex
                    let option = currentOptionIndex
                    let tab = Math.max(Math.min(numberOfTabs - 1, currentTabIndex + direction), 0)

                    if(tab == currentTabIndex){ // tab stayed, means we need to change option or section
                        option = Math.max(Math.min(numberOfSectionItems - 1, currentOptionIndex + direction), 0)

                        if(option < currentOptionIndex){ // same section
                            const prevItem = currentSection?.sectionItems[currentOptionIndex - 1] 
                            tab = (prevItem.tabs?.length ?? 1) - 1  // get max tab of previous option
                        } else if(option > currentOptionIndex) {
                            tab = 0
                        } else {  // option stayed, means we need to change section
                            section = Math.max(Math.min(numberOfSections - 1, currentSectionIndex + direction), 0)
                            tab = 0

                            if(section < currentSectionIndex){
                                tab = (prevSectionTabs?.length ?? 1) - 1  // get max tab of previous section
                                option = prevSection.sectionItems?.length - 1 ?? 0 // get max option
                            } else if(section > currentSectionIndex) {
                                option = 0
                            }
                        }
                    }

                    this.setState({ currentCustomizeItem: {section, option, tab}})
                }} className={"mlp-button-flex "}>
                    {direction > 0 ? ">" : "<"}
                </button>
            }
            var topbarButtons = []

            Object.keys(optionSections).forEach((sectionKey, si) => {
                const togSection = optionSections[sectionKey];
                const color = togSection.color ?? "black"

                // Get the top bar items
                togSection.sectionItems.forEach((tog, i) => {

                    const toggleName = tog.name;
                    const toggleButtonName = tog.button;
                    const isSelected = si == currentSectionIndex && this.state.currentCustomizeItem.option == i;

                    const colorClass = isSelected ? "white " + color + "-border" : color;

                    if(isSelected) {
                        this.currentButtonRef = React.createRef<HTMLButtonElement>();
                    }


                    var button = <button key={"button" + sectionKey + i.toString()} ref={isSelected ? this.currentButtonRef : null} onClick={() => {
                        this.setState({ currentCustomizeItem: {section: si, option: i, tab: 0}})
                    }} className={"mlp-button-flex " + colorClass}>
                        {i == 0 ? <div key={"section title" + sectionKey} className='sectionTitle-up' > {sectionKey} </div> : null}
                        {toggleButtonName ?? toggleName}
                        {/* {isSelected ? <div key={"linedown" + sectionKey + i.toString()} className={"line-up " + color} /> : null} */}
                    </button>;

                    topbarButtons.push(
                        button
                    )

                });
                
                if (si < Object.keys(optionSections).length - 1) topbarButtons.push(<div key={"separator" + sectionKey}  className='mlp-separator gray' />)
            });

            let finalContent = [];

            let tabs = [makeTabArrow(-1)]

            if(currentSectionGroupTabs) {
                currentSectionGroupTabs.forEach((t, ti) => {
                    tabs.push(makeTabButton(t.name, ti));
                });
            } else {
                tabs.push(makeTabButton(currentSectionGroup.fullname ?? currentSectionGroup.name, 0));
            }
            tabs.push(makeTabArrow(1))

            if (currentSectionTweakables) {
                const randomChoosable = []


                var disable = false;
                var specific = []
                for (var i = 0; i < currentSectionTweakables.length; i++) {
                    let _option = currentSectionTweakables[i]

                    const getOptionButtons = (o, final, index) => {

                        const makeOptionButtons = (optionIncrement, IconTag, key, showArrow = false) => { return <button disabled={o.canDisable && disable} key={key} onClick={() => {

                            if (choices && o?.choice) {
                                this.props.getMLP().setDirty();
                                const toChange = o.choice(choices);
                                this.props.getMLP()?.increment(toChange, optionIncrement);
                                this.forceRender();
                            }

                        }} className={"mlp-button-flex lightGray"} style={{ padding: "10px", textAlign: "center" }} >
                            {<IconTag key="Icon" className="" height="20px" width="20px" />}
                            {showArrow ? <div key="arrow" className={"sideArrow-" + (optionIncrement > 0 ? "right" : "left")} > {optionIncrement > 0 ? ">" : "<"} </div> : null}
                            {/* {optionName} */}
                        </button> }

                        const buttons = []

                        const onSlide = (value: React.ChangeEvent<HTMLInputElement>, range: number, flip: boolean) => {
                            if (choices && o?.choice) {
                                this.props.getMLP().setDirty();
                                const v = parseFloat(value.target.value) / range
                                this.props.getMLP()?.setPercent(o.choice(choices), flip ? 1 - v : v);
                            }
                        }

                        const getOptionValue = (flip: boolean) => {
                            if (choices && o?.choice) {
                                this.props.getMLP().setDirty();
                                let r = 0
                                lookAtToggle(
                                    o.choice(choices), 
                                    (i)=>{ r = i; }, 
                                    (p)=>{ r = flip ? 1 - p : p; }, 
                                    (k, _)=>{ }, // intended for number vals
                                )
                                return r;
                            }
                            return 0;
                        }

                        if(o.icons) {
                            buttons.push(makeOptionButtons(-1, o.icons.down, "L" + index.toString()))
                            buttons.push(makeOptionButtons(1, o.icons.up, "R" + index.toString()))
                        } else if(o.upDownIcon) {
                            buttons.push(makeOptionButtons(-1, o.upDownIcon, "L" + index.toString(), true))
                            buttons.push(makeOptionButtons(1, o.upDownIcon, "R" + index.toString(), true))
                        } else if(o.slider) {
                            const numberOfValues = o.precision ?? 16;
                            const overflow = Math.ceil(numberOfValues / 4);
                            const horiz = (o.slider as string)?.startsWith("horizontal");
                            const flip = (o.slider as string)?.endsWith("flip")
                            const def = (getOptionValue(flip) * numberOfValues).toString();
                            const key = (currentSectionGroup.name + def + i.toString() + "-" + index.toString())

                            const down = false
                            const hSlider = <div className={"sliderContainer "} key={"slider container " + key} ><input disabled={o.canDisable && disable}
                                key={key} 
                                onChange={
                                    (v)=>onSlide(v, numberOfValues, flip)
                                } 
                                type="range" 
                                min={(-overflow).toString()} 
                                max={(overflow + numberOfValues).toString()} 
                                defaultValue={def} 
                                value={null}
                                className={"slider" + (horiz ? "" : " vert")} 
                                orient={horiz ? "horizontal" : "vertical"}
                            /> </div>

                            buttons.push(hSlider)
                            
                        } else {
                            buttons.push(makeOptionButtons(o.increment ?? 1, o.icon ?? ShareIcon, index.toString()))
                        }

                        if((!final && o.icons) || o.separator) buttons.push(<div key={"Toggle separator"+index+i} className='mlp-separator lightGray' />)

                        return buttons;
                    }

                    let element;

                    if (_option.group) {

                        var inner = []
                        var currentDisabled = disable;

                        _option.group.forEach((groupOption, oi) => {
                            if(choices && !isSpecificValue(groupOption.choice(choices))) lookAtToggle(
                                groupOption.choice(choices), 
                                (i) => {
                                    disable = disable || (i == 0 && groupOption.disableIfDefault) // make sure there's no default in a default disabling spot
                                }, 
                                (p) => {}, 
                                (k, def)=>{ 
                                    disable = disable || (k == def && groupOption.disableIfDefault) // make sure there's no default in a default disabling spot
                                }, 
                            )
                            currentDisabled = currentDisabled && groupOption.canDisable
                            inner.push(...getOptionButtons(groupOption, oi >= _option.group.length - 1, oi));

                            if(choices && !groupOption.ignoreRandomize) randomChoosable.push(groupOption.choice(choices));
                        });

                        // boxShadow: "#00000030 0px 0px 3px",
                        // the toggles (buttons you click)
                        element = <td key={"col"+i} ><ContentCard key={"options" + i} style={{padding: "5px", margin: "0", backgroundColor: "white", opacity: currentDisabled ? "0.5" : "1" }} border={false} children={[
                            <div key="Control Button Group" style={{height: "60px", padding: "0", flexWrap: "nowrap", overflow: "visible", justifyContent: "space-evenly" }} className="mlp-customize-controls" >{inner}</div>,
                            { type: "Text", content: _option.name, style: { fontWeight: "bold", color: "black", textAlign: "center" } },
                        ]} /></td>

                    }
                    specific.push(element)

                };

                const randButton = <button key={"RANDCURRENT"} onClick={() => {
                    this.props.getMLP().randomize({all: randomChoosable}, {num: 6, amount: 3,}, () => { 
                        this.forceRender(); 
                    })
                }} className={"mlp-button-flex lightYellow emoji"} style={{ padding: "10px", textAlign: "center" }} >
                    🎲
                </button>

                const clearButton = <button key={"CLEARCURRENT"} onClick={() => {
                    this.props.getMLP().clean(randomChoosable)
                    this.forceRender();
                }} className={"mlp-button-flex lightRed emoji"} style={{ padding: "10px", textAlign: "center" }} >
                    🚫
                </button>

                const clearNRandButtons = <td style={{width: "60px",}} key={"col"+i} >
                    <ContentCard key={"options" + i} style={{ margin: "0", padding: "5px", }} border={false} children={[
                        <div key="Control Button Group" style={{display:"flex", flexDirection: "column", padding: "0", flexWrap: "nowrap", overflow: "visible", justifyContent: "space-evenly" }} className="mlp-customize-controls" >{
                            [randButton, clearButton]
                        }</div>,
                    ]} />
                </td>

                specific = [clearNRandButtons].concat(specific)

            }

            const jumpToButtons =  <div key="JUMP TO" ref={this.sectioncontrolScrollerRef} className="mlp-customize-controls" style={{ height: "auto", gap: "5px", padding: "5px 5px 5px" }}>
                {makeJumpToButton(
                    "Base", 
                    currentSectionIndex < 3, 
                    () => { this.setState({currentCustomizeItem: {section: 0, option: 0, tab: 0 }}) }
                )}
                {makeJumpToButton(
                    "Makeup", 
                    currentSectionIndex >= 3 && currentSectionIndex < 6, 
                    () => { this.setState({currentCustomizeItem: {section: 3, option: 0, tab: 0 }}) }
                )}
                {makeJumpToButton(
                    "Accessories", 
                    currentSectionIndex >= 6 && currentSectionIndex < 8, 
                    () => { this.setState({currentCustomizeItem: {section: 6, option: 0, tab: 0 }}) }
                )}
                {makeJumpToButton(
                    "Scene", 
                    currentSectionIndex >= 8, 
                    () => { this.setState({currentCustomizeItem: {section: 8, option: 0, tab: 0 }}) }
                )}
            </div>

            const color = currentSection.color ?? "white";
            finalContent.push(
                <ContentCard
                    key="Actual controls"
                    className={color + " " + color + "-border"}
                    style={{ textAlign: "left", margin: "-1px", padding: "0", borderLeft: "0", borderRight: "0", borderRadius: "0", overflow: "hidden",}}
                    border={true} children={[
                        <div key="SECTION TABS" className="mlp-customize-controls" style={{ height: "auto", gap: "5px", padding: "5px 5px 0px 5px" }}>
                            {tabs}
                        </div>,
                        <div 
                            key={"scrollingTable"} 
                            ref={this.scrollingTableRef} 
                            className='scrollingTable'
                        >
                            <table key="TABLE" ref={this.togglesTableScrollerRef}  style={{
                                borderCollapse: "separate", 
                                padding: "0px", 
                                flexWrap: "nowrap", 
                                justifyContent: "left", 
                                display: "table",
                                borderSpacing: "5px" 
                            }} className="mlp-customize-controls"  ><tbody key="TBODY" >
                                <tr key="ROW" >{specific}</tr>
                            </tbody></table>
                        </div>,
                    ]} 
                />,

                <div key="Top section buttons" ref={this.controlScrollerRef} style={{ padding: "25px 7px 0px", }} className="mlp-customize-controls" > 
                    {topbarButtons}
                </div>,
                jumpToButtons,
                // customSectionButtons,
            );
            // ].concat(finalContent)

            return <ContentCard key="Current Control List"
                className={(this.props.modeColor ?? "black") + "-border"}
                style={{ textAlign: "left", padding: "0px 0px",  background: "transparent", overflow: "hidden" }}
                border={true} children={ finalContent }
            />;

        }

        const currentMode = makeCustomizationItems(optionSections);
    

        return currentMode
    }
    
}