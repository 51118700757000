import React, { Component } from 'react';

import inspo from '../../static/inspiration.jpg'

import MyLittlePutin from '../components/MyLittlePutin';
import { ContentCard } from '../components/ContentCard';

import { PutinFooter } from '../components/PutinFooter';
import { lookAtToggle, merge, } from '../../shared/PutinChoices';
import { BasePage } from '../components/BasePage';


import ShareIcon from '../icons/share.svg'
import ResetIcon from '../icons/reset.svg'
import { unidecode } from '../components/SerializablePutin';

import { LocalPutinsLazy } from '../components/LocalPutinsLazy';
import { NamedPutin } from '../components/NamedPutin';
import CustomizePutin from '../components/CustomizePutin';


// tslint:disable-next-line:no-var-requires
require("./index.less");

function getJsonFromUrl() {
    let uuid = null as string
    let json = null as any
  
    if (typeof window !== 'undefined') {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        uuid = params.edit;
        const jsonStr = unidecode(params.data);
        json = jsonStr ? jsonStr : null;
    }

    return {uuid, json};
}

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    mode: "custom" | "slap" | "random";
    didSave: boolean,
    loading: boolean,
    error?: "noGL" | "missingImage"
    poorPerf: boolean,
    faceLocked: boolean,
    force: boolean,
    showNsfw: boolean
}

const linkIcon = "⬀";

// https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const MyLittlePutinLazy = React.lazy(() =>
    import("../components/MyLittlePutin")
)

const WeaponsBarLazy = React.lazy(() =>
    import("../components/WeaponsBar")
)


// FIXME: THIS CODE IS ASS AND HACKY AND RUSHED BUT WHATEVER
export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    uuid: string;
    json: NamedPutin

    constructor(props) {
        super(props);

        this._putin = React.createRef<MyLittlePutin>();

        const searchBar = getJsonFromUrl()
        this.uuid = searchBar.uuid;
        this.json = searchBar.json;

        this.state = {showNsfw: true, faceLocked: false, mode: "slap", force: false, didSave: false, loading: true, poorPerf: false,};

        if(this.uuid) {
            this.modeCustom()
        }
    }

    _putin: React.RefObject<MyLittlePutin>;

    private openImageInNewTab() {
        this.put()?.openInNewTab();
    }

    forceRender() {
        this.setState({ force: !this.state.force })
    }


    put() {
        const p = this.state.loading ? null : this._putin?.current;
        return p;
    }

    private modeSlap() {
        this.setState({ didSave: false, mode: "slap" });
        this.put()?.changeMode("slap")
    }


    private modeCustom() {
        this.setState({ didSave: false, mode: "custom" });
        this.put()?.changeMode("custom");
    }

    private reset() {
        this.put()?.modeReset()
    }
    canShow = true;
    public render() {
        
        const isSSR = typeof window === "undefined"

        const isSlap = (this.state?.mode == "slap");
        const isRandom = (this.state?.mode == "random");
        const isCustom = (this.state?.mode == "custom");

        const slapColor = "black"
        const customColor = "rainbow"
        const sceneColor = "yellow"
        const modeColor = isSlap ? slapColor : isCustom ? "rainbow" : sceneColor

        const save = <div key="SAVE BUTTONS" className="mlp-customize-controls" style={{ gap: "5px", paddingBottom: "0px", paddingTop: "10px" }}>
            <button key="SAVE BUTTON" onClick={()=>this.put()?.modeShare()} className="mlp-button green" style={{ fontSize: "22px", flex: "1" }} > <ShareIcon key="ShareIcon" height="18px" width="18px" /> Save / Share </button>
        </div>

        const resetAndSave = <div key="RESET AND DL BUTTONS" className="mlp-customize-controls" style={{ gap: "5px", paddingBottom: "0px", }}>
        <button key="RESET BUTTON" onClick={()=>this.reset()} className={"red mlp-button"} style={{ flex: "0.8" }} > <ResetIcon  key="RESETIcon" height="15px" width="15px" /> Reset</button>
            <button key="DL BUTTON" onClick={()=>this.put()?.modeShare()} className={"saveColor mlp-button"} style={{ flex: "0.8" }} > <ShareIcon key="DLIcon" height="15px" width="15px" /> Save / Share</button>
        </div>

        const saveInfo = {
            type: "Note", style: {fontSize:"14px"}, content:
                <p key="dl msg">⚠️ If download isn't working,
                    <a key="new tab link" onClick={this.openImageInNewTab.bind(this)} href="#"> click here </a>
                    to open the image in a new tab. Then you can save it! </p>
        };

        const mlp = <MyLittlePutinLazy 
            key="mlp"
            skipSplash={this.uuid != null || this.json != null} 
            showButtons={true} 
            canRandomize={true}
            fetchInternet = {
                {uuid: this.uuid, done: (p, e) => {
                    // if(!e) this.modeCustom();
                }}
            } 
            initialMode="slap" 
            onLoad={ () => {
                window.history.replaceState(null, "My Little Putin", window.location.href.split('?')[0]);
                this.setState({ loading: false });
            }} 
            canShowNsfw={ (canShow) => {
                if(!isCustom) this.forceRender();
            }}
            json={this.json}
            ref={this._putin} 
        />

        const hWeapons = <WeaponsBarLazy 
            key={"weapons bar"} 
            onClick={(w)=>{ 
                if(this.put()?.weapon == w) {
                    this.put()?.setNeedsSlapMessage();
                } else {
                    this.put()?.setWeapon(w); 
                    this.forceRender(); 
                }
            }} 
            weapon={this.put()?.weapon}  
            nsfw={this.put()?.verified}
        />

        const hLoading= <ContentCard 
            key={"LOADING CUSTOM INDICATRO"}
            border={true} 
            className={(modeColor ?? "black") + "-border"} 
            children={[
                {type: "Heading1", content: "Loading..."}
            ]}
        /> 

        const instructionsMode =<ContentCard border={false} key="MODE Instructions cont "  style={{ display: "flex", alignItems: "flex-start", justifyContent:"space-between", padding: "0", margin: "0" }} children={[ 
            <ContentCard key="MODE Instructions " className={"underlineButton"} border={true}  style={{ width: '100%', marginRight: "0px"}} children={[
                { type: "Heading3", style:{ padding:"0", }, content: "MY LITTLE PUTIN" },
                { type: "Other", style:{ padding:"0", }, content: <div key="i1" className='instructionsFlex orange-text'> <b>💥 </b><p key="text"> Click or tap to <b key="bold"> <button style={{padding: "0"}} key="slap buton" onClick={this.modeSlap.bind(this)}>SLAP PUTIN</button>!</b> </p></div> },
                { type: "Other", style:{ padding:"0", }, content: <div key="i1.1" className='instructionsFlex purple-text'> <b>🧸 </b><p key="text"> Select different <b key="bold">TOYS</b> to play with! </p></div> },
                { type: "Other", style:{ padding:"0", }, content: <div key="i2" className='instructionsFlex'> <b>🌈 </b><p key="text"><button style={{padding: "0"}} key="customize buton" className='rainbow-text' onClick={this.modeCustom.bind(this)}>CUSTOMIZE YOUR OWN LITTLE PUTIN</button> </p></div> },
                { type: "Other", style:{ padding:"0", }, content:<div key="i3" className='instructionsFlex red-text'>  <b> ❌ </b> <p className='red-text' key="text"> <button onClick={()=>this.reset()}>RESET</button> Little Putin. </p></div> },
                { type: "Other", style:{ padding:"0", }, content:<div key="i4" className='instructionsFlex save-text'>  <b>☁️ </b> <p className='green-text' key="text"><button onClick={()=>this.put()?.modeShare()}>SAVE</button> your Little Putin and share with friends! </p></div> },
                { type: "Other", style:{ padding:"0", }, content:<div key="i5" className='instructionsFlex blue-text'>  <b> 📷 </b> <p className='blue-text' key="text"> <button onClick={()=>this.put()?.modeDownload()}>DOWNLOAD</button> your Little Putin as an image...  </p></div> },
                // saveInfo,
                
            ]} />,
        ]} />


            let currentMode;

            if (isSlap) {
                if(!isSSR) {
                    currentMode = [
                        <React.Suspense key="Weapons Bar Lazy" fallback={<div key="Loading Wep" className={""} />}>
                            {hWeapons}
                        </React.Suspense>
                    ];
                } else {
                    currentMode = []
                }
            }else if (this.state.loading) {
                currentMode = [hLoading]
            } else {
                currentMode = [<CustomizePutin key={"customs"} getMLP={()=>{ return this.put(); }} modeColor={modeColor} />]
            }

                
        const makeSectionButton = (title, selected, color, callback) => {
            const colorClass = selected ? "white " + "black-text " + color + "-border " : color;
            return <button key={"TITLE "+title} onClick={callback} style={{ flex: "1", position:"relative", borderRadius:"10px" }} className={"mlp-button-flex condensed " + colorClass}>
            {title}
            </button>
        }


        const sectionButtons = <div>
            <div key="Main Mode Controls" className="mlp-customize-controls" style={{ height: "auto", gap: "5px",  padding: "0", marginTop: '5px' }}>
                {/* {makeSectionButton("Random", isSlap, slapColor, () => { this.modeRandom(); if(isRandom) {this.put()?.randomize(this.put()?.AllChoices, {num: 1, amount: 20, wait: 400});} })} */}
                {makeSectionButton("Slap Putin", isSlap, slapColor, () => { this.modeSlap(); if(isSlap) {this.put()?.setNeedsSlapMessage();} })}
                {makeSectionButton("Customize", isCustom, customColor, () => { this.modeCustom(); })}
            </div>
            <div key="SECTION LINES" style={{ display:"flex", width: "100%", height:"5px", justifyContent: "space-around", touchAction:"none", pointerEvents:"none", userSelect: "none"}} >
                <div key="SLAP LINE" className={isSlap ? slapColor+"-border white" : ""} style={{ visibility: isSlap ? "visible" : "hidden", borderWidth:"2px", width: "0px", height: "100%"}} />
                <div key="CUSTOM LINE" className={isCustom ? customColor+"-border white" : ""} style={{ visibility: isCustom ? "visible" : "hidden", borderWidth:"2px", width: "0px", height: "100%"}} />
            </div>
        </div>

        var linkCount = 0;
        const refugees = [
            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://www.globalempowermentmission.org/mission/ukraine-crisis/" target="_blank" > Global Empowerment Mission{linkIcon} </a> },
            // { type: "Text", content: "This nonprofit has organized a welcome center at the border of Ukraine and Poland. Donated funds will help support refugees with relocation assistance, such as transportation, lodging and airfare; it will also help with other initiatives, including war-zone evacuation of those still in Ukraine and getting aid directly into Ukraine." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://donate.coreresponse.org/give/393950/#!/donation/checkout" target="_blank" > Community Organized Relief Effort (CORE){linkIcon} </a> },
            // { type: "Text", content: "Community Organized Relief Effort (CORE) is a nonprofit organization dedicated to immediate crisis response. CORE is working in Poland to help support Ukrainian refugees. Donations will go toward providing cash assistance to displaced families and distributing hygiene kits with soap, hand sanitizer, toothbrushes, thermal blankets and other essential hygiene items." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://help.rescue.org/donate-fr/ukraine-crisis?initialms=ws_modl_fy22_ukraine_mmus&ms=ws_modl_fy22_ukraine_mmus" target="_blank" > The International Rescue Committee{linkIcon} </a> },
            // { type: "Text", content: "The International Rescue Committee is a longstanding organization that provides resources to those fleeing countries in crisis, including providing cash assistance, medical treatment and more. The organization is currently on the ground in both Poland and Ukraine to provide support to those who have had to flee their homes." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://pck.pl/wspieraj-nas/" target="_blank" > The Polish Red Cross{linkIcon} </a> },
            // { type: "Text", content: "As of Feb. 28, most of the Ukrainian refugees have crossed into the country’s eastern neighbor, Poland. The Polish Red Cross has provided humanitarian reception points at Ukrainian-Polish borders to assist those entering with humanitarian aid and medical support." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://www.malteser-international.org/en/donation.html" target="_blank" > Malteser International{linkIcon} </a> },
            // { type: "Text", content: "Malteser International is the humanitarian relief agency of the Sovereign Order of Malta and is currently involved in relief efforts for Ukraine. The organization is providing food, shelter, emergency medical care and more both within Ukraine and neighboring countries. You can donate specifically to its efforts in Ukraine on the donation page." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://giving.unhcr.org/en/ukraine/" target="_blank" > UNHCR{linkIcon} </a> },
            // { type: "Text", content: "The United Nations Refugee Agency, is raising funds to provide emergency supply kits, helping set up transit centers and providing temporary shelter to help Ukrainians displaced by the war. The organization has created a restricted donation fund where 100% of the donations will go toward supporting Ukrainian refugees." },
        ]


        const supplies = [

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://donate.wck.org/give/236738/#!/donation/checkout" target="_blank" > World Central Kitchen{linkIcon} </a> },
            // { type: "Text", content: "World Central Kitchen (WCK), established in 2010, is an organization that deploys chefs directly to disaster areas to provide hot meals to those in need. WCK is currently working in Ukraine and along its borders in neighboring countries." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://my.care.org/site/Donation2?idb=2076970746&df_id=31071&mfc_pref=T&31071.donation=form1" target="_blank" > CARE{linkIcon} </a> },
            // { type: "Text", content: "CARE has launched a humanitarian appeal to support the most vulnerable Ukrainians, particularly women-headed households and the elderly." },
            // { type: "Text", content: "CARE’s responses will be regional and leverage partnerships in affected countries to deliver emergency assistance. Our priority is to meet the immediate needs of affected families through the distribution of critical food and water supplies, as well as hygiene kits, cash assistance and psychosocial support." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://kse.ua/support/donation" target="_blank" > Kyiv School of Economics{linkIcon} </a> },
            // { type: "Text", content: "The Kyiv School of Economics has created an initiative to provide equipment to those directly involved in the war through its charitable foundation. This equipment includes protective kits, such as bulletproof vests and helmets, for groups including the State Emergency Service of Ukraine, the Ukrainian Paramedic Association and the Ukrainian Territorial Defense Forces. As of March 10, it has raised $5.9 million." },

        ]

        const frontlines = [

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://donate.redcrossredcrescent.org/ua/donate/~my-donation?_cv=1" target="_blank" > The Ukrainian Red Cross{linkIcon} </a> },
            // { type: "Text", content: "The Red Cross is often on the frontlines of war serving to help those affected by armed conflict. The Ukrainian Red Cross is currently assisting people inside the country by aiding evacuations and providing shelter, food and basic necessities." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://donate.doctorswithoutborders.org/secure/monthly?_ga=2.17709630.649451870.1647309724-1812634735.1647309724" target="_blank" > Doctors Without Borders{linkIcon} </a> },
            // { type: "Text", content: "As the war in Ukraine escalates, Doctors Without Borders/Médecins Sans Frontières (MSF) has halted normal activities and is mobilizing emergency operations. They now have teams in Ukraine, Poland, Moldova, Hungary, Slovakia, Russia, and Belarus. MSF is an independent and impartial organization committed to providing medical humanitarian assistance to people affected by the war no matter who they are or where they are." },

            // { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://vostok-sos.org/en/ukraine-under-fire-support-vostok-sos-aid-operation/" target="_blank" > Vostok SOS{linkIcon} </a> },
            // { type: "Text", content: "Vostok SOS is a Ukraine-based non-governmental organization (NGO) dedicated to assisting areas of conflict within Ukraine. The organization is currently accepting donations to help aid local people, evacuate the vulnerable and provide trauma support after shelling." },

        ]

        const support = [
            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://www.globalgiving.org/projects/ukraine-crisis-relief-fund/" target="_blank" > GlobalGiving Ukraine Crisis Relief Fund{linkIcon} </a> },
            // { type: "Text", content: "GlobalGiving is raising funds to support locally-led organizations throughout Ukraine. Any donations to the fund will provide essentials for refugees, health, psychological and support and access to education and economic assistance." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://voices.org.ua/en/donat/" target="_blank" > Voices of Children{linkIcon} </a> },
            // { type: "Text", content: "Created in 2015, the Voices of Children Foundation has been providing psychological support to children affected by war in the Donetsk and Luhansk regions of Ukraine through art therapy, video storytelling, mobile psychologists and more. Now, the organization is working to support children across the country with emergency psychological assistance and assisting in the evacuation process." },

            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://www.hi-us.org/support-ukraine" target="_blank" > Humanity & Inclusion{linkIcon} </a> },
            // { type: "Text", content: "Humanity & Inclusion (H&I) is an international nongovernmental organization that works to support people with disabilities in areas of conflict, poverty and disaster. H&I workers have arrived in Ukraine to assess how it can help distribute medical equipment, provide post-operative care for the injured and provide mental health support. Donations will go toward supplying emergency kits and rehabilitation and mental health support sessions." },

        ]

        const other = [
            { type: "Heading3", content: <a key={"Icon"+(linkCount++).toString()} href="https://www.gofundme.com/f/keep-ukraines-media-going?fbclid=IwAR2jWrF8nx344X94zBesGMlM1pMEPbV8vMv1N5Dvg5-WGQXcdBzA8QDDGF0" target="_blank" > Media Support{linkIcon} </a> },
            // { type: "Text", content: "Donating directly toward the media in Ukraine can help fight against Russian misinformation and keep Ukrainians informed of the latest developments. A GoFundMe has been launched by various organizations to help keep Ukraine’s media outlets running during the war." },

        ]

        var sepi = 0
    const separator = () => <div key={"long separator"+sepi++} className="gray" style={{ height: "3px", borderRadius: "2px", width: "100%", margin: "15px 0" }} />;

        const cards = [
            <ContentCard key="support" border={true} children={[
                { type: "Title", content: "🇺🇦 SUPPORT UKRAINE" },

                // { type: "Text", content: "In February 2022, Valdimir Putin began a violent Russian invasion of Ukraine. People are still in urgent need of support." },
                {type: "Text", content: `
                On February 24 2022, Vladimir Putin launched a violent Russian invasion of Ukraine. 
                The attack set off a series of events over the next six months, including unprecedented sanctions against Russia and an expanded NATO. 
                `},
                // {type: "Text", content: "In less than two weeks, over 2 million people fled Ukraine to neighbouring countries, becoming refugees in Poland, Moldova, Hungary and Romania. There are now more than 4 million refugees who need support. "},

                // { type: "Warning", content: <p>People are being <b>MURDERED</b> by Putin's troops. Millions have been displaced. Thousands are lacking food, water and the necessary resources they need to live.</p> },
                { type: "Warning", content: <p>Tens of thousands have been <b>MURDERED</b> by Putin's troops. Over 5.3 million people have been displaced, the largest wave of refugees in OECD countries since World War II.</p> },

                { type: "Note", content: "Please take some time to look at and consider donating to the following organizations." },


            // ]} />,
            // <ContentCard key="support" border={true} children={[
                separator(),
                <ContentCard key="refugees" className='white'  border={false} children={[

                    { type: "Heading1", content: "🌻 REFUGEES" },
                    
                    ...refugees,
                ]} />,separator(),
                <ContentCard key="food"  border={false} children={[

                    { type: "Heading1", content: "🌽 Resources / Supplies" },

                    ...supplies,
                ]} />,separator(),
                <ContentCard key="med"  border={false} children={[

                    { type: "Heading1", content: "🚑 Frontlines" },

                    ...frontlines,
                ]} />,separator(),
                <ContentCard key="support"  border={false} children={[

                    { type: "Heading1", content: "🧠 Support" },

                    ...support,
                ]} />,separator(),
                <ContentCard key="other" border={false} children={[

                    { type: "Heading1", content: "📷 Other" },

                    ...other,
                ]} />,
            ]} />,


            <ContentCard key="credits" border={true} children={[
                { type: "Heading1", content: "CREDITS", },
                // { type: "Note", content: <div key="forbe" ><p key="forbe text"> Lots of information was directly taken from <a key={"Icon"+(linkCount++).toString()} href="https://www.forbes.com/advisor/personal-finance/donate-relief-to-ukraine/" target="_blank" > here</a>. </p></div> },
                // separator(),
                { type: "Heading3", content: "Inspiration", style: { fontWeight: "bold" } },
                { type: "Note", content: "In 2016, Alexander V. Tsvetkov created this image of Putin in makeup, which was oulawed as \"extremist\" in Russia. My Little Putin was originally inspired by this image." },

                { type: "Other", content: <img key="img" style={{ borderRadius: "5px", width: "100%", marginLeft: "auto", marginRight: "auto" }} src={inspo} /> },

            ]} />,

        ]

        const notNft = <ContentCard key="important" className="pink-border" border={true} children={[
            { type: "Heading2", content: "IMPORTANT" },
            { type: "Warning", content: <p key="TEXT"> This is <b key="BOLD">NOT</b> an NFT. This will <b key="BOLD2">NEVER</b> be an NFT. </p> },
            { type: "Note", content: "This webapp is free, don't get scammed. If you'd like to contribute to something, please consider donating directly to one of the organizations listed below." },
        ]} />;

        const local = <ContentCard key="local" border={true} children={[
            { type: "Heading2", content: "RECENT PUTINS" },
            <LocalPutinsLazy key="GALLERY" expanded={false} show={10} size={{x:125, y: 125}} />,
            { type: "Heading3", content: <a key="VIEW GALLERY" style={{textAlign:"center", display:"block", width: "100%", paddingTop: "10px"}} href="/gallery"> View Gallery </a> },
        ]} />

        // <a style={{marginLeft: "7px"}}>DONATE</a>
        const iran =  <ContentCard key="iran" border={true} className={"black"} style={{ padding: "5px 10px", }} children={[
            {type: "Heading3", content: <div style={{fontSize: "15px", display: "flex",justifyContent: "space-between"}}> <p className='white-text'>🆘 SUPPORT IRANIAN WOMEN </p>  <div style={{padding: "0px", textAlign:"right"}}> <a href='https://www.cntraveler.com/story/protests-in-iran-2022'>MORE INFO</a> </div></div> }
        ]} />

        const leftSide = [
            iran,
            !isSSR && <React.Suspense key="SUSSY" fallback={<div key="SQUARE" className={"square"} />}>
                {mlp}
            </React.Suspense>,
            sectionButtons,
            <div key="currentmode container" style={{marginTop:"-5px"}} children={currentMode} />,
        ]
        
        const rightSide = [
            // notNft,
            instructionsMode,
            local,
            ...cards,
            <PutinFooter key="FOOTER" />
        ]

        return <BasePage key="Main Page" children={[
            <div key="SPLIT" className="SplitPage" >
                <div key="Left" className="PageContent " children={[
                    ...leftSide
                ]} />
                <div key="Right" className="PageContent" children={[
                    ...rightSide
                ]} />
            </div>
        ]} />
    }
}